body {
	min-height: 100vh;
}

/* html {
	min-height: 100%;
	margin: 0;
} */

.arrow-icon {
	text-align: center;
	margin: 0 auto;
	height: 72px;
}

.cell {
	height: 40px;
	padding: 0px !important;
}

.footer-span {
	display: inline-flex;
  	align-items: center;
}

.header {
	margin: 0px;
}

.header-middle {
	margin-top: 10px;
	margin-bottom: 10px;
}

.home-text {
	text-align: left;
}

.landmark-name {
	color: #DA4869;
}

.level {
	text-align: center;
	cursor: pointer;
	height: 40px;
	vertical-align: middle;
	display: table-cell;
	width: 344px;
}

.levels-block {
	padding-top: 20px;
	margin: auto;
	width: 280px;
}

.modal .modal-content {
	background-color: peachpuff;
	border: grey 5px solid;
}

.modal-header {
	border: none !important;
	text-align: center;
	margin: auto;
}

.modal-footer {
	border: none !important;
}

.nav-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	padding: 1px !important
}

.nav-header {
	margin: 0px;
	height: 100%;
	vertical-align: bottom !important;
	font-weight: bold;
	font-family: Arial, Helvetica, sans-serif;
}

.nav-left {
	width: 60px;
}

.nav-middle {
	align-self: flex-end;
}

.nav-right {
	text-align: right;
	width: 60px;
}

.row {
	margin: 4px;
}

.settings-row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 30px;
}

.stats-row {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: 30px;
	border-bottom: black 2px dashed;
}

.stats-div {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: space-between;
	margin: auto;
	cursor: pointer;
}

.zoom-button {
	height: 40px;
	width: 40px;
}

.zoom-button:focus {
	box-shadow: none !important;
	outline: none !important;
	outline-offset: none !important;
}

@media all and (min-width:0px) and (max-width: 600px) {
	canvas {
		display: block !important;
		width: 320px !important;
		height: 320px !important;
	}

	.footer-div {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin: auto;
		background: transparent;
		text-align: center;
		color: black;
		padding-top: 20px;
		padding-bottom: 18px;
		width: 320px;
		margin: 0 auto;
	}

	.globe-container {
		width: 320px;
		clip-path: circle(160px at 160px 160px);
		text-align: center;
		margin: auto;
		margin-top: 20px;
	}

	.header-homepage {
		width: 320px;
		margin: auto;
	}

	.navbar {
		border-bottom: 2px solid black;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		background-color: transparent;
		padding: 20px 0px 0px 0px !important;
		width: 320px;
		margin: auto;
	}

	.scene-container {
		position: relative;
		max-height: 320px !important;
		max-width: 320px !important;
	}

	.scene-container > * {
		position: relative;
		max-height: 320px !important;
		max-width: 320px !important;
	}

	.stats-block {
		padding-top: 20px;
		margin: auto;
		width: 320px;
	}

	.top-of-page {
		background: transparent;
		text-align: center;
		color: black;
		padding-top: 20px;
		width: 320px;
		margin: 0 auto;
	}

	.zoom-controls {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		/* padding: 20px 0px 0px 0px !important; */
		width: 320px;
		margin: 0 auto;
	}
}

@media all and (min-width:601px) {
	canvas {
		display: block !important;
		width: 600px !important;
		height: 600px !important;
	}

	.footer-div {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		margin: auto;
		background: transparent;
		text-align: center;
		color: black;
		padding-top: 20px;
		padding-bottom: 18px;
		width: 600px;
		margin: 0 auto;
	}

	.globe-container {
		width: 600px;
		clip-path: circle(300px at 300px 300px);
		text-align: center;
		margin: auto;
		margin-top: 20px;
	}

	.header-homepage {
		width: 600px;
		margin: auto;
	}

	.navbar {
		border-bottom: 2px solid black;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		background-color: transparent;
		padding: 20px 0px 0px 0px !important;
		width: 600px;
		margin: 0 auto;
	}

	.scene-container {
		position: relative;
		max-height: 600px !important;
		max-width: 600px !important;
	}

	.stats-block {
		padding-top: 20px;
		margin: auto;
		width: 320px;
	}

	.top-of-page {
		background: transparent;
		text-align: center;
		color: black;
		padding-top: 20px;
		width: 600px;
		margin: auto;
	}

	.zoom-controls {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		/* padding: 20px 0px 0px 0px !important; */
		width: 600px;
		margin: 0 auto;
	}
}
